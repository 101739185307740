<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Edit {{ person.first_name }} {{ person.last_name }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-2">
        <v-text-field
          label="First Name"
          v-model="person.first_name"></v-text-field>
      </v-col>
      <v-col class="col-2">
        <v-text-field
          label="Middle Name"
          v-model="person.middle_names"></v-text-field>
      </v-col>
      <v-col class="col-2">
        <v-text-field
          label="Last Name"
          v-model="person.last_name"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-2">
        <v-text-field
          label="Next of kin"
          v-model="person.next_of_kin"></v-text-field>
      </v-col>
      <v-col class="col-2">
          <v-text-field
            label="Relationship"
            v-model="person.next_of_kin_relationship"></v-text-field>
        </v-col>
      <v-col class="col-2">
        <v-text-field
          label="Next of kin telephone"
          v-model="person.next_of_kin_telephone"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="person.start_date"
              label="Start date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              suffix="*"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="startDate"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="col-3">
        <v-text-field
          label="Holiday entitlement"
          v-model="person.holiday_entitlement"></v-text-field>
      </v-col>
  </v-row>
  <v-row>
    <v-col class="col-2">
      <v-text-field
        label="Telephone"
        v-model="person.telephone"></v-text-field>
    </v-col>
    <v-col class="col-2">
      <v-text-field
        label="Mobile"
        v-model="person.mobile"></v-text-field>
    </v-col>
    <v-col class="col-2">
      <v-text-field
        label="Email"
        v-model="person.email"></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="col-4">
      <v-text-field
        label="Address"
        v-model="person.address"></v-text-field>
    </v-col>
    <v-col class="col-2">
      <v-text-field
        label="Postcode"
        v-model="person.post_code"></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="col-2">
      <v-text-field
        label="NI Number"
        v-model="person.ni_number"></v-text-field>
    </v-col>
    <v-col class="col-3">
      <v-menu
            v-model="menuTwo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="person.date_of_birth"
                label="Date of birth"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                suffix="*"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateOfBirth"
              @input="menuTwo = false"
            ></v-date-picker>
          </v-menu>
      </v-col>
  </v-row>
  <v-row>
    <v-col class="col-2">
      <v-text-field
        label="Doctor"
        v-model="person.doctor"></v-text-field>
    </v-col>
    <v-col class="col-4">
      <v-text-field
        label="Surgery address"
        v-model="person.surgery_address"></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="col-4">
      <v-checkbox
          label="Is this person currently active"
          v-model="person.active"
      ></v-checkbox>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="col-3">
      <v-select
        label="Is this person a crew member?"
        v-model="person.is_crew"
        :items="isCrew"></v-select>
    </v-col>
    <v-col class="col-3">
      <v-select
        label="Is this person admin?"
        v-model="person.admin"
        :items="isAdmin"></v-select>
    </v-col>
  </v-row>
  <v-row v-if="person.is_crew">
    <v-col class="col-3">
      <v-text-field
        label="Usual vehicle"
        v-model="person.crew_member.usual_vehicle"></v-text-field>
    </v-col>
    <v-col class="col-3">
      <v-select
        label="Is this person paid bonus?"
        v-model="person.crew_member.bonus"
        :items="isPaidBonus"></v-select>
    </v-col>
  </v-row>
  <v-row v-if="person.is_crew">
    <v-col class="col-3">
      <v-select
        label="Department"
        v-model="person.crew_member.department_id"
        :items="departments"></v-select>
    </v-col>
    <v-col class="col-3" v-if="person.is_crew">
      <v-text-field
        v-if="person.crew_member.department_id === 4"
        prefix="£"
        label="What is this person's annual salary?"
        v-model="person.crew_member.hourly_rate"
        ></v-text-field>
        <v-text-field
        v-else
        prefix="£"
        label="What is this person's hourly rate?"
        v-model="person.crew_member.hourly_rate"
        ></v-text-field>
    </v-col>
  </v-row>
  <v-row v-if="person.is_crew">
    <v-col class="col-3" v-if="person.is_crew">
      <v-text-field
        label="Maximum shift length"
        v-model.number="person.crew_member.max_shift_length"
        type="number"
        step="0.01"
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="col-3">
      <v-checkbox
        label="Is this person currently employed"
        v-model="person.active"
        ></v-checkbox>
    </v-col>
  </v-row>
  <v-row>
    <v-col class="col-4">
      <v-btn @click="savePerson">Save Person</v-btn>
    </v-col>
  </v-row>
  <v-dialog
    v-model="showMessage"
    max-width="400"
  >
    <v-card>
      <v-card-text class="pt-4">
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <router-link to="/admin/personnel">
          <v-btn
            x-small
            color="orange darken-2">
            Back to all personnel
          </v-btn>
        </router-link>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'AdminPersonnelEdit',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      dateOfBirth: null,
      startDate: null,
      person: {},
      message: '',
      showMessage: false,
      menu: false,
      menuTwo: false,
      isCrew: [
        {
          value: false,
          text: 'No',
        },
        {
          value: true,
          text: 'Yes',
        },
      ],
      isAdmin: [
        {
          value: false,
          text: 'No',
        },
        {
          value: true,
          text: 'Yes',
        },
      ],
      isPaidBonus: [
        {
          value: false,
          text: 'No',
        },
        {
          value: true,
          text: 'Yes',
        },
      ],
      departments: [
        {
          value: 1,
          text: 'Paint',
        },
        {
          value: 2,
          text: 'Thermo',
        },
        {
          value: 3,
          text: 'Both',
        },
        {
          value: 4,
          text: 'Office',
        },
      ],
    };
  },
  watch: {
    dateOfBirth () {
      this.person.date_of_birth = this.formatDate(this.dateOfBirth);
    },
    startDate () {
      this.person.start_date = this.formatDate(this.startDate);
    },
  },
  methods: {
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    getPerson() {
      const personId = this.$route.params.id;
      axios.get(`/personnel/getSingle/${personId}.json?token=${this.token}`)
        .then((response) => {
          this.person = response.data.person;
          this.dateOfBirth = this.person.date_of_birth;
          this.startDate = this.person.start_date;
          this.person.date_of_birth = this.formatDate(this.person.date_of_birth);
          this.person.start_date = this.formatDate(this.person.start_date);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    savePerson() {
      this.person.isCrewMember = this.isCrewMember;
      axios.post(`/personnel/update.json?token=${this.token}`, this.person)
        .then((response) => {
          this.message = response.data.message;
          this.showMessage = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getPerson();
  },
};
</script>
